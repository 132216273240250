export default {
  simulation: {
    "Static modeling": "Statische Modellierung",
    "Static result": "Statische Ergebnisse",
    "Dynamic modeling": "Dynamische Modellierung",
    "Dynamic result": "Dynamische Ergebnisse",
    "Simulation saved successfully": "Simulation erfolgreich gespeichert",
    "Error saving simulation": "Fehler beim Speichern der Simulation",
    "You are only allowed to read the data": "Sie dürfen die Daten nur lesen",
    Setting: "Einstellung",
    "Share this page": "Teile diese Seite",
    Saving: "Speichern",
    Save: "Speichern",
    "Catenary Name": "Oberleitungsname",
    "Oh, you have not sent a static request yet!":
      "Oh, Sie haben noch keine statische Anfrage gesendet!",
    "First, go to static modeling and send a new request":
      "Gehen Sie zuerst zur statischen Modellierung und senden Sie eine neue Anfrage",
    "Go to static modeling": "Gehen Sie zur statischen Modellierung",
    "You can see the static configuration of your design as well as the required length/static force of each dropper":
      "Sie können die statische Konfiguration Ihres Designs sowie die erforderliche Länge/statische Kraft jedes Tropfers sehen",
    "Go to span view": "Gehe zu span Ansicht",
    "Go to full view": "Gehe zu vollansicht",
    "Go to heatmap": "Gehen Sie zur Heatmap",
    "Go to Analysis Section": "Gehen Sie zum Abschnitt Analyse",
    "Dropper number": "Dropper number ",
    "Dropper force": "Dropper-Kraft",
    "Dropper elasticity": "Dropper-Elastizität",
    // Section: "Abschnitt",
    "Default catenary simulated": "Standardoberleitung simuliert",
    "Dropper length": "Dropper Länge ",
    Dropper: "Dropper",
    Force: "Gewalt",
    Elasticity: "Elastizität",
    Length: "Länge",
    "Download excel file": "Excel-Datei herunterladen",
    // Span: "Spanne",
    "Oh, you have not sent a dynamic request yet!":
      "Oh, Sie haben noch keine dynamische Anfrage gesendet!",
    "First, go to dynamic modeling and send a new request":
      "Gehen Sie zuerst zur dynamischen Modellierung und senden Sie eine neue Anfrage",
    "Go to dynamic modeling": "Gehen Sie zur dynamischen Modellierung",
    "Analysis Section": "Section Analyse",
    "Pantograph Moment": "Pantograph Moment",
    "Displacement (MM)": "Hubraum (MM)",
    "Velocity (M/S)": "Geschwindigkeit (M/S)",
    "Acceleration (M/S2)": "Beschleunigung (M/S2)",
    "Contact force (N)": "Kontaktkraft (N)",
    "Pantograph's head": "Pantograph's Kopf ",
    time: "Zeit",
    Backward: "Rückwärts",
    Pause: "Pause",
    Play: "Spiel",
    Forward: "Nach vorne",
    "STD of contact force (0 Hz to 5 Hz)=":
      "STD der Kontaktkraft (0 Hz bis 5 Hz)=",
    "STD of contact force (5 Hz to 20 Hz)=":
      "STD der Kontaktkraft (5 Hz bis 20 Hz)=",
    "STD of contact force (0 Hz to 20 Hz)=":
      "STD der Kontaktkraft (0 Hz bis 20 Hz)=",
    "STD (incl. low pass filter 20 Hz)=": "STD (inkl. Tiefpassfilter 20 Hz)=",
    "Actual maximum of contact force=":
      "Tatsächliches Maximum der Kontaktkraft=",
    "Actual minimum of contact force=":
      "Tatsächliches Minimum der Kontaktkraft=",
    "Percentage of loss of contact=": "Prozentsatz des Kontaktverlusts=",
    "Maximum support uplift=": "Maximaler Unterstützungsauftrieb =",
    "Uplift at support": "Uplift bei der Unterstützung",
    "Dropper Force": "Dropper-Kraft",
    Displacement: "Verschiebung",
    Acceleration: "Beschleunigung",
    Velocity: "Geschwindigkeit",
    Pantograph: "Pantograph",
    Max: "Max",
    Min: "Min",
    Distance: "Distanz",
    "Analysis section from end of": "Analyse section vom Ende des",
    to: "zu",
    Apply: "Anwenden",
    "Request for custom pantograph model":
      "Anfrage für kundenspezifisches Stromabnehmermodell",
    "Pantograph property": "Pantograph-Eigenschaft",
    "Manual input": "Manuelle Eingabe",
    "K contact (N/m)": "K-Kontakt (N/m)",
    "Auto input": "Automatische Eingabe",
    "moving load": "bewegte Last",
    "Force (N)": "Kraft (N)",
    "Dynamic Response": "Dynamische Antwort",
    "Load Dynamic Response": "Dynamische Antwort laden",
    Uplift: "Erheben",
    force: "Gewalt",
    "Back to modeling": "Zurück zum Modellieren",
    "train speed": "Zuggeschwindigkeit",
    "Mechanical Properties": "Mechanische Eigenschaften",
    "Oh sorry, this calculation can't be solved!":
      "Oh sorry, diese Rechnung kann nicht gelöst werden!",
    "The support team will contact you soon as possible.":
      "Das Support-Team wird sich schnellstmöglich mit Ihnen in Verbindung setzen.",
    "If there is something you can write in the chat section.":
      "Wenn es etwas gibt, können Sie im Chat-Bereich schreiben.",
    "Messenger Wire": "Messenger Kabel",
    "Messenger Wire Support": "Messenger Drahtunterstützung ",
    "Steady Arm": "Stabiler Arm",
    "Stitch Wire": "Stitch Kabel",
    "Contact Wire": "Contact Kabel",
    "The number of": "Die Zahl der",
    "overlap spans": "Spannweiten überlappen",
    between: "zwischen",
    and: "und",
    "is equal to": "ist gleich",
    Preview: "Vorschau",
    "First you should calculate static data":
      "Zuerst sollten Sie statische Daten berechnen",
    Calculate: "Berechnung",
    Projects: "Projekte",
    "Estimated calculation time": "Geschätzte Berechnungszeit",
    Cancel: "Absagen",
    "Calculation started": "Berechnung gestartet",
    "Request cancelled": "Anfrage abgebrochen",
    "Error happened!": "Fehler passiert!",
    "Request for VR 4D film": "Anfrage für VR 4D Film",
    Ok: "Ok",
    "*The damping of the other mode shapes will be considered based on the extrapolation of the assigned values":
      "*Die Dämpfung der anderen Eigenformen wird anhand der Extrapolation der zugeordneten Werte berücksichtigt",
    "Mass of the clamp to catenary wire (kg)":
      "Masse der Klemme zum Fahrdraht (kg)",
    "Validation Warning": "Validierungswarnung",
    "the clamp is so light or too heavy, check the technical data":
      "die Schelle zu leicht oder zu schwer ist, überprüfen Sie die technischen Daten",
    "Validation Error": "Validierungsfehler",
    "The weight of clamp can not be less than zero or more that 5kg":
      "Das Gewicht der Klemme darf nicht weniger als null oder mehr als 5 kg betragen",
    "Tension per wire (kN)": "Spannung pro Draht (kN)",
    "Young's modulus": "Elastizitätsmodul",
    "the Young's modulus in messenger wire is 90-120 kN/mm2 normally":
      "Der Elastizitätsmodul im Tragdraht beträgt normalerweise 90-120 kN/mm2",
    "the value is not valid, check the technical data of the cable":
      "der Wert ist ungültig, überprüfen Sie die technischen Daten des Kabels",
    "Mass per length (kg/m)": "Masse pro Länge (kg/m)",
    "the mass per unit length of this cable is 0.8-1.1 kg/m normally":
      "Die Masse pro Längeneinheit dieses Kabels beträgt normalerweise 0,8-1,1 kg/m",
    "Cross section": "Kreuzung",
    "the cross section of this cable is 30-40 mm2 normally":
      "Der Querschnitt dieses Kabels beträgt normalerweise 30-40 mm2",
    "Max frequency": "Maximale Frequenz",
    "*The maximum frequencies that will be considered in the simulation. The higher considered frequencies, the higher precision in results and the more required simulation time":
      "*Die maximalen Frequenzen, die in der Simulation berücksichtigt werden. Je höher die betrachteten Frequenzen, desto präziser die Ergebnisse und desto länger die benötigte Simulationszeit",
    "Number of steady arms": "Anzahl der stabilen Arme",
    "Number of steady arms are either 1 or 2!":
      "Anzahl der Lünette wahlweise 1 oder 2!",
    "the steady arm is so short or too long, check the technical data":
      "der Seitenhalter so kurz oder zu lang ist, überprüfen Sie die technischen Daten",
    "The length of steady arm can not be less than zero or more that 10":
      "Die Länge des Seitenarms darf nicht kleiner als null oder größer als 10 sein",
    "Mass per length (kg)": "Masse pro Länge (kg)",
    "the mass per unit length of steady arm is 0.6-1.1 kg/m normally":
      "Das Gewicht pro Längeneinheit des Seitenhalters beträgt normalerweise 0,6-1,1 kg/m",
    "Mass of clamp (kg)": "Masse der Schelle (kg)",
    "The damping of steady arm can not be negative or high value.":
      "Die Dämpfung des Seitenstabilisators darf nicht negativ oder hoch sein.",
    "Damping (N.s/m)": "Dämpfung (N.s/m)",
    "The damping of steady arm can not be more than 100 N.s/m":
      "Die Dämpfung des Seitenhalters darf nicht mehr als 100 N.s/m betragen",
    "Constant values": "Konstante Werte",
    "Stiffness (kN/m)": "Steifigkeit (kN/m)",
    "The stiffness of the support is either low or high":
      "Die Steifigkeit des Trägers ist entweder niedrig oder hoch",
    "The stiffness of the support can not be less than 0 or more than 10000, if the support is rigid, please check the bottom above":
      "Die Steifheit der Stütze darf nicht weniger als 0 oder mehr als 10000 betragen, wenn die Stütze starr ist, überprüfen Sie bitte unten oben",
    "Damping (Ns/m)": "Dämpfung (Ns/m)",
    "The damping of the support is either low or high":
      "Die Dämpfung der Stütze ist entweder gering oder hoch",
    "The damping of the support can not be less than 0 or more than 10000, if the support is rigid, please check the bottom above":
      "Die Dämpfung der Stütze darf nicht kleiner als 0 oder größer als 10000 sein, wenn die Stütze starr ist, überprüfen Sie bitte unten oben",
    "Load response": "Antwort laden",
    Fixed: "Fest",
    "Load dynamic response": "Dynamik laden",
    "the tension in messenger wire is 13-21kN normally":
      "Die Spannung im Tragseil beträgt normalerweise 13-21 kN",
    "This linear analytical simulation is not suitable for extremely high tension or super low tension cables":
      "Diese lineare analytische Simulation ist nicht für Kabel mit extrem hoher Spannung oder mit extrem niedriger Spannung geeignet",
    "the cross section of this cable is 80-120 mm2 normally":
      "der Querschnitt dieses Kabels beträgt normalerweise 80-120 mm2",
    "This changes applied on dropper that created with our default values":
      "Diese Änderungen wurden auf Dropper angewendet, die mit unseren Standardwerten erstellt wurden",
    "Stiffness and Damping": "Steifigkeit und Dämpfung",
    "A rigid connection between contact wire and messenger cable will be considered":
      "Eine starre Verbindung zwischen Fahrdraht und Tragseil wird berücksichtigt",
    "Young's modulus (kN/mm2)": "Elastizitätsmodul (kN/mm2)",
    "The weight of clamp can not be less than zero or more that 3kg":
      "Das Gewicht der Klemme darf nicht weniger als null oder mehr als 3 kg betragen",
    "Cross section (mm2)": "Querschnitt (mm2)",
    "the cross section of this cable is 5-50 mm2 normally":
      "der Querschnitt dieses Kabels beträgt normalerweise 5-50 mm2",
    "the mass per unit length of dropper is 0.6-0.2 kg/m normally":
      "Die Masse pro Längeneinheit des Tropfers beträgt normalerweise 0,6–0,2 kg/m",
    "Clamp on messenger wire (Kg)": "Klemme am Tragseil (Kg)",
    "Clamp on contact wire (Kg)": "Klemme am Fahrdraht (Kg)",
    "Using buckling theory": "Anwendung der Knicktheorie",
    "A spring with a bilinear stiffness between contact wire and messenger cable will be considered":
      "Betrachtet wird eine Feder mit bilinearer Steifigkeit zwischen Fahrdraht und Tragseil",
    "Max compression force (N)": "Maximale Kompressionskraft (N)",
    "Assign a value": "Weisen Sie einen Wert zu",
    "If you get the buckling threshold low, the calculation time would be too much. The High threshold, makes your dropper rigid":
      "Wenn Sie die Knickschwelle niedrig erhalten, wäre die Berechnungszeit zu lang. Die hohe Schwelle macht Ihre Pipette starr",
    "The dropper stiffness in tension can not be less than zero.":
      "Die Steifheit des Tropfers unter Spannung kann nicht kleiner als null sein.",
    Analytical: "Analytisch",
    "Stiffness in tension (N/m)": "Zugsteifigkeit (N/m)",
    "the dropper stiffness is normally 100kN/m in tension, check the technical data":
      "die Dropper-Steifigkeit beträgt normalerweise 100 kN/m auf Zug, überprüfen Sie die technischen Daten",
    "The dropper stiffness in tension can not be less than zero or more than 500kN/m":
      "Die Steifheit des Droppers unter Spannung darf nicht weniger als null oder mehr als 500 kN/m betragen",
    "Damping for compression (N.s/m)": "Dämpfung für Kompression (N.s/m)",
    "The damping of dropper should not be too small or too high":
      "Die Dämpfung des Tropfers sollte nicht zu klein oder zu hoch sein",
    "The damping of dropper cannot be negative or high value.":
      "Die Dämpfung des Tropfers darf nicht negativ oder hoch sein.",
    "Damping for tension (N.s/m)": "Dämpfung für Spannung (N.s/m)",
    "Stiffness for compression (N/m)": "Steifigkeit für Kompression (N/m)",
    "the dropper stiffness is normally less than 100 tension, check the technical data":
      "die Tropfersteifigkeit ist normalerweise kleiner als 100 Spannung, überprüfen Sie die technischen Daten",
    "Stiffness for tension (N/m)": "Steifigkeit für Zug (N/m)",
    "Add span": "Span hinzufügen",
    "(first span)": "(erste span)",
    "(last span)": "(letzte span)",
    "Stitch wire tension": "Stichdrahtspannung",
    "the tension of stitch wire is normally 10-40% of the tension of messenger cable":
      "Die Spannung des Heftdrahts beträgt normalerweise 10-40 % der Spannung des Tragseils",
    "the tension of stitch wire can not be zero or more than the tension of messenger cable":
      "Die Spannung des Stichdrahts darf nicht null oder höher sein als die Spannung des Tragseils",
    "the length of stitch wire can not be zero or more than the length of span":
      "Die Länge des Stichdrahts darf nicht null oder größer als die Spannweite sein",
    "normally 2-4 droppers located in stitch wire":
      "normalerweise 2-4 Tropfer im Heftdraht",
    "The messenger wire height can not be less than contact wire height":
      "Die Tragdrahthöhe darf nicht geringer sein als die Fahrdrahthöhe",
    "The catenary encumbrance is 1.2-1.8 normally":
      "Die Oberleitungsbelastung beträgt normalerweise 1,2-1,8",
    "The contact wire height can not be negative or zero or more than messenger cable height":
      "Die Fahrdrahthöhe darf nicht negativ oder null oder größer als die Tragseilhöhe sein",
    "consider the recommendation of EN 15273-2-2013":
      "Beachten Sie die Empfehlung der EN 15273-2-2013",
    "the length of span could not be negative or zero":
      "die Länge der Spanne konnte nicht negativ oder null sein",
    "Length of span is normally 27-63m":
      "Die Spannweite beträgt normalerweise 27-63 m",
    "Add Dropper": "Dropper hinzufügen",
    "The number of droppers cannot be less than zeros or too high":
      "Die Anzahl der Dropper darf nicht kleiner als Null oder zu hoch sein",
    "The stager can not be more than span length":
      "Der Stager darf nicht länger als Spannweite sein",
    "The stager is ±30 cm normally": "Der Stager beträgt normalerweise ±30 cm",
    "individual setting for steady arm":
      "individuelle Einstellung für Seitenständer",
    "Messenger wire stagger": "Messenger Wire Staffelung",
    "Contact wire stagger": "Fahrdraht versetzt",
    "New Catenary": "Neue Oberleitung",
    "Catenary Pool": "Oberleitungspool",
    "Catenary deleted successfully!": "Oberleitung erfolgreich gelöscht!",
    "Something went wrong!": "Etwas ist schief gelaufen!",
    "Catenary duplicated successfully!": "Oberleitung erfolgreich dupliziert!",
    "Catenary left successfully!": "Oberleitung erfolgreich verlassen!",
    "No catenaries found": "Keine Oberleitungen gefunden",
    "No selected": "Nein ausgewählt",
    CatenaryType: "Oberleitungstyp",
    Access: "Zugriff",
    Sample: "Probe",
    unknow: "unbekannt",
    "Created time": "Erstellte Zeit",
    "Updated time": "Zeit aktualisiert",
    "Created by": "Erstellt von",
    Permission: "Erlaubnis",
    Duplicate: "Duplikat",
    Leave: "Verlassen",
    Delete: "Löschen",
    "All Access": "alle Zugriff",
    Full: "Voll",
    Read: "Lesen",
    Write: "Schreiben",
    "All Catenary": "Alle Oberleitung",
    Stitched: "Genäht",
    Compound: "Verbindung",
    "View catenary": "Oberleitung ansehen",
    "Catenary created": "Oberleitung erstellt",
    "Catenary model": "Oberleitungsmodell",
    "Apply to all": "Bewerben Sie sich für alle",
    "New simple catenary": "Neue einfache Oberleitung",
    "New stitched catenary": "Neue genähte Oberleitung",
    "New compound catenary": "Neue zusammengesetzte Oberleitung",
    "Request for custom catenary": "Anfrage für benutzerdefinierte Oberleitung",
    "Search between catenaries by name":
      "Suche zwischen oberleitungen nach namen",
    "Stitched catenary": "Genähte Oberleitung",
    "The bilinear mode is not yet implemented":
      "Der bilineare Modus ist noch nicht implementiert",
    "The dropper can't be located on either previous dropper or out of considered span":
      "Der Dropper kann sich weder auf dem vorherigen Dropper noch außerhalb der betrachteten Spanne befinden",
    "Dropper Detail": "Dropper Detail",
    "Rigid dropper": "Steifer Tropfer",
    "Distance from left-side dropper": "Abstand vom linken Tropfer",
    "Dropper is either too close to or so far from the previous dropper.":
      "Dropper ist entweder zu nah oder zu weit entfernt vom vorherigen Dropper.",
    "The vertical position of contact wire":
      "Die vertikale Position des Fahrdrahts",
    "in the dropper location": "in der Dropper-Position",
    "The presags of the contact wire in dropper points are less than 50mm normally":
      "Die Vorsprünge des Fahrdrahtes in den Dropper Points betragen normalerweise weniger als 50 mm",
    "The presags of the contact wire in dropper points can not be more than 200mm":
      "Die Vorsprünge des Fahrdrahtes in den Dropper Points dürfen nicht mehr als 200 mm betragen",
    "individual setting": "individuelle Einstellung",
    " A spring with a bilinear stiffness between contract wire and messenger cable will be considered":
      "Betrachtet wird eine Feder mit bilinearer Steifigkeit zwischen Zugdraht und Tragseil",
    "Bilinear stiffness spring for droppers":
      "Feder mit bilinearer Steifigkeit für Tropfer",
    "Elasticity of overhead contact line at the dropper points":
      "Elastizität der Oberleitung an den Hängepunkten",
    "Something in static model is changed, you will need to recalculate if you want to see updated results":
      "Wenn sich etwas am statischen Modell geändert hat, müssen Sie eine Neuberechnung durchführen, wenn Sie aktualisierte Ergebnisse sehen möchten",
    "Add Section Insulator": "Streckenisolator hinzufügen",
    "Add Clamp": "Klemme hinzufügen",
    "Custom Property": "Benutzerdefinierte Eigenschaft",
    "Choose from library": "Aus der Bibliothek auswählen",
    "Section Insulator Detail": "Detail der Abschnittsisolatoren",
  },
};
