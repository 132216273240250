import { types } from "../types";

const initialState = {
  numberOfWidgetLoaded: 0,

  isLoading: true,
  isStopped: false,

  isAuthenticated: null,
  serverError: null,

  redirectTo: "",
  navBadges: {
    home: 0,
    customization: 0,

    archivePoints: 0,
    archiveCalendarTrips: 0,
    archivePantographs: 0,

    empowermentSimulation: 0,
    empowermentSupervision: 0,
    empowermentTriggers: 0,

    extraSettings: 0,
    extraHelp: 0,
  },
};

export default function panelReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.PANEL_LOADING:
      return { ...state, isLoading: payload };
    case types.PANEL_STOPPED:
      return { ...state, isStopped: payload };
    case types.PANEL_REDIRECT:
      return { ...state, redirectTo: payload };
    case types.ACCOUNT_AUTHENTICATED:
      return { ...state, isAuthenticated: payload };
    case types.SET_SERVER_ERROR:
      return { ...state, serverError: payload };
    case types.UPDATE_NUMBER_OF_WIDGET_LOADED:
      return {
        ...state,
        numberOfWidgetLoaded:
          state.numberOfWidgetLoaded < payload
            ? state.numberOfWidgetLoaded + 1
            : state.numberOfWidgetLoaded,
      };
    default:
      return state;
  }
}
