export default {
  supervision: {
    "Acc data number": "Zugangsdatennummer",
    "Acceleration STD": "Beschleunigung STD",
    "Acceleration-Miss data": "Beschleunigungs-Miss-Daten",
    "Add a figure": "hinzufügen",
    "Amir filter": "Amir filter",
    "Device Name": "Gerätename",
    "Download Signals": "herunterladen",
    "Download Video": "Video herunterladen",
    "Figure property": "Figureneigenschaft",
    "Figure type": "Figurentyp",
    "Impact time": "Einwirkzeit",
    "Impact Signal": "Signal Importieren",
    "Import Video": "Importieren",
    "Make report": "Bericht schreiben",
    "Max comparison ratio": "Maximales Vergleichsverhältnis",
    "Not found map points": "Kartenpunkte nicht gefunden",
    "Not Recorded": "Nicht aufgenommen",
    "Not Requested": "Nicht angefordert",
    "One minute summary": "Eine Minute Zusammenfassung",
    "one minute video": "einminütiges Video",
    "Seen by device": "Gesehen vom Gerät",
    "Search to find abnormalities until": "Suche nach Anomalien bis",
    "Signal filter": "Signalfilter",
    "Solar panel": "Sonnenkollektor",
    "Sorry, this signal doesn't have data":
      "Tut mir leid, dieses Signal enthält keine Daten",
    "Train speed": "Zuggeschwindigkeit",
    "Trip information": "Reiseinformationen",
    "Wind turbine": "Windkraftanlage",
    "Window length": "Fensterlänge",
    "Window rms": "Fenster rms",
    "Zig-Zag": "Zickzack",
    Acceleration: "Beschleunigung",
    Backward: "Rückwärts",
    Cancel: "Absagen",
    Copied: "Kopiert",
    Copy: "Kopieren",
    CT: "CT",
    Date: "Datum",
    Download: "Herunterladen",
    fails: "scheitert",
    Forward: "Nach vorne",
    Go: "gehen",
    "height (mm)": "Höhe (mm)",
    Height: "Höhe",
    in: "in",
    Kur: "Kur",
    Max: "Max",
    Min: "Min",
    Ok: "Ok",
    out: "aus",
    Outside: "Draußen",
    PANTOboard: "PANTOboard",
    Pause: "Pause",
    Play: "Spiel",
    Received: "Empfangen",
    Remove: "Entfernen",
    Rendering: "Rendern",
    Requested: "Angefordert",
    "Send to device": "An Gerät senden",
    Std: "Std",
    Temperature: "Temperatur",
    Thickness: "Dicke",
    Time: "Zeit",
    Video: "Video",
    "zigzag (mm)": "Zickzack- (mm)",
    "Point info": "Punktinfo",
    "Last status": "Letzter Status",
    "Possibility of a problem": "Möglichkeit eines Problems",
    "The shocks are severe": "Die Erschütterungen sind schwerwiegend",
    Previous: "Vorherige",
    Next: "Nächste",
    "Trip info": "Reiseinformationen",
    Device: "Gerät",
    "No abnormalities found in this time":
      "In dieser Zeit wurden keine Auffälligkeiten festgestellt",
    "History & Forecast": "Geschichte und Prognose",
    "Go to check": "Gehen Sie zur Überprüfung",
    Checked: "Geprüft",
    "Create new status": "Neuen Status erstellen",
    "Point status removed!": "Punktstatus entfernt!",
  },
};
